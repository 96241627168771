<template>
  <v-card :color="color">
    <v-card-title class="text-h6">
      <v-row class="ml-12 mr-3" align="center" justify="center">
        <v-col cols="11">
          <v-row align="center" justify="center" class="">
          {{ titulo }}
          </v-row>
        </v-col>
      <v-col cols="1">
      <v-menu
          close-on-click
          offset-x
          top
          transition="slide-x-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="black" icon>
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn
                block
                text
                @click.prevent="dialogHistorico = true"
            >
              Histórico Completo
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      </v-col>
      </v-row>
    </v-card-title>
      <v-card-text class="white text--secondary">
        <v-container>
          <slot name="default"/>
          <v-divider vertical/>
          <span v-if="historico.length" class="primary--text text-uppercase font-weight-medium">
          Realizada {{ formatDate(ultimaAcao.data, 'dia') }}
          às {{ formatDate(ultimaAcao.data, 'hora') }}
        </span>
        </v-container>
      </v-card-text>
    <v-card-actions class="white">
      <v-container>
        <v-row class="justify-end">
          <slot name="botao"/>
        </v-row>
      </v-container>
    </v-card-actions>
    <slot name="extra"/>
    <v-dialog v-model="dialogHistorico" width="600">
      <v-card
          :color="color"
          :key="historico.length"
      >
        <v-card-title class="text-h6">
          Histórico - {{titulo}}
        </v-card-title>
        <v-card-text class="overflow-y-auto white" style="height: calc(100% - 110px)">
          <div class="mt-5" v-if="historico.length">
            <h4 class="font-weight-regular" v-for="h in historico" :key="h.data">
              Ação realizada em <a class="red--text font-weight-bold">{{ formatDate(h.data, 'dia') }}</a>
              as <a class="red--text font-weight-bold">{{ formatDate(h.data, 'hora') }}</a>
              por: <a class="red--text font-weight-bold">{{ h.usuarioCpf }}</a>
            </h4>
          </div>
          <div v-else class="mt-5">
          <span class="font-weight-medium">
            Nenhuma ação realizada.
          </span>
          </div>
        </v-card-text>
        <v-card-actions class="white justify-end">
          <v-btn color="primary" @click="dialogHistorico = false">
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import luxon from "@/core/luxon";

export default {
  name: "AcaoItem",

  props: {
    color: {type: String, required: true},
    titulo: {type: String, required: true},
    historico: {type: Array, required: true, default: () => []}
  },

  data() {
    return {
      loading: false,
      dialogHistorico: false
    };
  },

  methods: {
    formatDate(data, formato) {
      return data
          ? formato === 'hora'
              ? this.dataConverter(data).toFormat("TT") : this.dataConverter(data).toFormat("dd/LL/yyyy")
          : "";
    },
    dataConverter(data){
      return luxon.dataConverter(data);
    }
  },

  computed: {
    ultimaAcao() {
      return this.historico.length ? this.historico[0] : {};
    }
  }
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
