import Vue from "vue";

export default {

    alocarTodasAsOpcoes(processoOid){
        return Vue.axios.post("alocacao/alocarTodasAsOpcoes/" + processoOid);
    },

    alocarUmaOpcao(opcaoOid){
        return Vue.axios.post("alocacao/alocarUmaOpcao/" + opcaoOid);
    },

    recuperarOpcoesNaoAlocadas(processoOid){
        return Vue.axios.get("alocacao/opcoesNaoAlocadas/" + processoOid);
    }
}