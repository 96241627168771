<template>
  <AcaoItem titulo="Alocação" :color="color"
            :historico="historico.historicosAlocacao">
    <template v-slot:default>
      <p>
        Clique em Alocar para realizar a alocação
        <br/>
        O processo deve estar em andamento e as incrições encerradas
      </p>
      <v-select
          outlined
          dense
          item-value="oid"
          item-text="nome"
          :items="listaOpcoesAlocacao"
          :disabled="alocacaoJaRealizada"
          v-model="opcaoSelecionada"
      />
    </template>
    <template v-slot:botao>
      <v-btn
          class="mt-1"
          :loading="loading"
          :disabled="!processo.podeFazerAcoes || alocacaoJaRealizada"
          color="primary"
          outlined
          @click="realizarAlocacao"
      >
        Alocar
      </v-btn>
    </template>
  </AcaoItem>
</template>

<script>
import AcaoItem from "./AcaoItem";
import alocacaoService from "@/services/alocacao.service";
import {mapActions} from "vuex";

export default {
  name: "AlocacaoItem",
  components: {AcaoItem},
  props: {
    color: {type: String, required: true},
    processo: {type: Object, required: true},
    historico: {type: Object, required: true}
  },

  data() {
    return {
      loading: false,
      opcaoSelecionada: 0,
      listaOpcoesAlocacao: [],
      opcaoPadrao: { nome: "Todas as opções não alocadas", oid: 0 },
      alocacaoJaRealizada: false
    }
  },

  async created(){

    let loader = this.$loading.show();

    await this.recuperarOpcoesNaoAlocadas();

    loader.hide();
    
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),

    async realizarAlocacao(){
      let loader = this.$loading.show();

      if(this.opcaoSelecionada === 0){
        await alocacaoService.alocarTodasAsOpcoes(this.processo.oid).then(async () => {
          await this.recuperarOpcoesNaoAlocadas();
          loader.hide();
          this.$emit("feito");
          this.exibirAviso({
            tipo: "success",
            mensagem: "Alocação realizada com sucesso"
          });
        }).catch(error => {
          loader.hide();
          let mensagem = "Não foi possível realizar a alocação";
          if (error.response.status === 409) {
            mensagem = error.response.data.msg;
          }
          this.exibirAviso({
            mensagem: mensagem,
            tipo: "error"
          });
        });

      } else {
        await alocacaoService.alocarUmaOpcao(this.opcaoSelecionada).then(async () => {
          await this.recuperarOpcoesNaoAlocadas();
          loader.hide();
          this.$emit("feito");
          this.exibirAviso({
            tipo: "success",
            mensagem: "Alocação realizada com sucesso"
          });
        }).catch(error => {
          loader.hide();
          let mensagem = "Não foi possível realizar a alocação";
          if (error.response.status === 409) {
            mensagem = error.response.data.msg;
          }
          this.exibirAviso({
            mensagem: mensagem,
            tipo: "error"
          });
        });

      }

    },

    async recuperarOpcoesNaoAlocadas(){
      await alocacaoService.recuperarOpcoesNaoAlocadas(this.processo.oid).then((response) => {
        if(!response.data.length){
          this.alocacaoJaRealizada = true

        }

        this.listaOpcoesAlocacao = response.data;
        this.listaOpcoesAlocacao.unshift(this.opcaoPadrao);
        this.opcaoSelecionada = 0
      });
    }
  }
}
</script>

<style scoped>

</style>